<template>
  <div>
    <loader-component v-if="loading"/>
    <div class="row align-items-center justify-content-center">
      <div class="col-md-3">
        <label for="">Select center</label>
        <v-select
            :options="centers"
            :reduce="centers => centers.id"
            v-model="institute_id"
            label="name"
            class="h-100"
            name="id"
            placeholder="Select center"
            required>
        </v-select>
      </div>
      <div class="col-md-2">
        <label for="">Product type</label>
        <b-form-select v-model="product_type" :options="product_types"></b-form-select>
      </div>
      <div class="col-md-2">
        <label for="">From</label>
        <input type="date" v-model="start_date" class="form-control">
      </div>
      <div class="col-md-2">
        <label for="">To</label>
        <input type="date" v-model="end_date" class="form-control">
      </div>
      <div class="col-md-1 mt-8 text-center">
        <button type="button" class="btn btn-info" @click="getReports">Filter</button>
      </div>
      <div class="col-md-1 mt-8">
        <button type="button" class="btn btn-danger mx-5" @click="clear">Clear</button>
      </div>
    </div>
    <div class="row mt-10">
      <div class="col-md-12 mb-3" v-for="(report, index) in reports" :key="index">
        <div class="card">

          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <div v-if="report.patient">
                  <ValueWithSubTitle sub_title="Patient"/>
                  <h4>{{ report.patient.fullname }}</h4>
                </div>
                <div>
                  <ValueWithSubTitle sub_title="Service Provider"/>
                  <h4>{{ report.doctor ? report.doctor.fullname : 'N/A' }}</h4>
                </div>
              </div>
              <div class="col-md-2">
                <div>
                  <ValueWithSubTitle sub_title="Total"/>
                  <h5>{{ report.total }}</h5>
                  <ValueWithSubTitle sub_title="Payment Status"/>
                  <h5>{{ report.status }}</h5>
                </div>
              </div>
              <div class="col-md-2">
                <div>
                  <ValueWithSubTitle sub_title="Collected by"/>
                  <h4>{{ report.created_user_name }}</h4>
                </div>
              </div>
              <div class="col-md-3">
                <div>
                  <ValueWithSubTitle sub_title="Discount(%)"/>
                  <h4>{{ report.discount_percentage }}</h4>
                  <div v-if="report.discount_reason">
                    <ValueWithSubTitle sub_title="Discount reason"/>
                    <h4>{{ report.discount_reason }}</h4>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div>
                  <ValueWithSubTitle sub_title="Invoice Date"/>
                  <h5>{{ report.invoice_date }}</h5>
                  <div v-if="report.comment">
                    <ValueWithSubTitle sub_title="Comment"/>
                    <p>{{ report.comment }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-pagination
            v-model="currentPage"
            :total-rows="total_count"
            :per-page="perPage"
            size="lg"
            @change="getReports"
            class="my-0"
            aria-controls="doctorList"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ValueWithSubTitle from "../reusable-component/ValueWithSubTitle";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "ServiceWiseReport",
  components: {ValueWithSubTitle},
  data() {
    return {
      institute_id: '',
      start_date: this.$route.query.start_date ? this.$route.query.start_date : null,
      end_date: this.$route.query.end_date ? this.$route.query.end_date : null,
      centers: [],
      reports: [],
      currentPage: 1,
      perPage: 20,
      total_count: 0,
      loading: false,
      product_type: this.$route.query.product_type ? this.$route.query.product_type : '',
      product_types: [
        {
          text: 'Therapy',
          value: 'therapy',
        },
        {
          text: 'Consultancy',
          value: 'appointment',
        },
        {
          text: 'Registration fee',
          value: 'registration_fee',
        },
        {
          text: 'Ponseti (Plaster)',
          value: 'Ponseti (Plaster)',
        },
        {
          text: 'Ponseti (Surgery)',
          value: 'Ponseti (Surgery)',
        },
        {
          text: 'Ponseti (Tenotomy)',
          value: 'Ponseti (Tenotomy)',
        },
        {
          text: 'Brace',
          value: 'brace',
        },
      ]
    }
  },
  created() {
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
    if (this.$route.query.institute_id) {
      this.institute_id = parseInt(this.$route.query.institute_id);
    }
    this.centerList();
    this.getReports();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Center wise service list", route: "/service-wise-report"},
    ]);
  },
  methods: {
    getReports(value) {
      this.loading = true;
      if (Number(value)) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }

      const url = `${process.env.VUE_APP_URL}/api/v2/report/orkocenter/service/reports?api_token=${localStorage.getItem('api_token')}${this.institute_id ? '&institute_id=' + this.institute_id : ''}${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}&limit=${this.perPage}&offset=${correctValue ? correctValue : 0}${this.product_type ? '&product_type=' + this.product_type : ''}`
      axios.get(url).then(res => {
        this.loading = false;
        if (res.data.status_code == 200) {
          this.reports = res.data.data;
          this.total_count = parseInt(res.data.total_count);

        } else {
          this.$snotify.error(res.data.message);
        }
      })
    },
    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.loading = false
              this.centers = response.data.data
            }
          })
    },
    clear() {
      this.institute_id = '';
      this.start_date = null;
      this.end_date = null;
      this.product_type = '';
      this.getReports();
    }
  }
}
</script>

<style scoped>

</style>
