<template>
    <div class="card">
      <loader-component v-if="loading"/>
      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-4">
            <div class="c_tab_area_filter">
                <b-dropdown id="dropdown-1" :text="periodConverter[filter.period]" class="bg-black">
                    <b-dropdown-item :active="filter.period == 'this_month'" @click="setPeriod('this_month')">This Month</b-dropdown-item>
                    <b-dropdown-item :active="filter.period == 'this_week'" @click="setPeriod('this_week')">This Week</b-dropdown-item>
                    <b-dropdown-item :active="filter.period == 'last_month'" @click="setPeriod('last_month')">Last Month</b-dropdown-item>
                    <b-dropdown-item :active="filter.period == 'last_week'" @click="setPeriod('last_week')">Last Week</b-dropdown-item>
                </b-dropdown>
            </div>
          </div>
          <div class="col-md-12">
            <b-table 
                sticky-header="70vh"
                :items="items" 
                :fields="fields"
                bordered
                table-class="c_tbl"
                thead-class="c_tbl_head text-center"
                tbody-class="text-center c_tbl_body"
            >
              <!-- Table Head Edit  -->
              <template v-slot:head(childPatientByAge)="data"> 
                <span v-html="data.field.label" class="d-block mb-2 pb-2 border-bottom border-light"></span>
                <div class="row row-cols-2">
                  <span class="small font-weight-bold">Patient</span>
                  <span class="small font-weight-bold">Service</span>
                </div>
              </template>
              <template v-slot:head(adultPatientByAge)="data"> 
                <span v-html="data.field.label" class="d-block mb-2 pb-2 border-bottom border-light"></span>
                <div class="row row-cols-2">
                  <span class="small font-weight-bold">Patient</span>
                  <span class="small font-weight-bold">Service</span>
                </div>
              </template>
              <template v-slot:head(total)="data"> 
                <span v-html="data.field.label" class="d-block mb-2 pb-2 border-bottom border-light"></span>
                <div class="row row-cols-2">
                  <span class="small font-weight-bold">Patient</span>
                  <span class="small font-weight-bold">Service</span>
                </div>
              </template>

              <!-- Table Body Edit  -->
                <template #cell(serviceName)="data">
                    {{ serviceConverter[data.item.serviceName]?serviceConverter[data.item.serviceName]:data.item.serviceName }}
                </template>
                <template #cell(childPatientByAge)="data">
                  <div class="row row-cols-2">
                    <div class="col-sm-6">
                      <template v-if="data.item.patientByAge.child.patient === 0"></template>
                      <template v-else>{{ data.item.patientByAge.child.patient }}</template>
                    </div>
                    <div class="col-sm-6">
                      <template v-if="data.item.patientByAge.child.service === 0"></template>
                      <template v-else>{{ data.item.patientByAge.child.service }}</template>
                    </div>
                  </div>
                </template>
                
                <template #cell(adultPatientByAge)="data">
                  <div class="row row-cols-2">
                    <div class="col-sm-6">
                      <template v-if="data.item.patientByAge.adult.patient === 0"></template>
                      <template v-else>{{ data.item.patientByAge.adult.patient }}</template>
                    </div>
                    <div class="col-sm-6">
                      <template v-if="data.item.patientByAge.adult.service === 0"></template>
                      <template v-else>{{ data.item.patientByAge.adult.service }}</template>
                    </div>
                  </div>
                </template>
                <template #cell(total)="data">
                  <div class="row row-cols-2">
                    <div class="col-sm-6">
                      <template v-if="data.item.patientByAge.child.patient + data.item.patientByAge.adult.patient === 0"></template>
                      <template v-else>
                        {{ data.item.patientByAge.child.patient + data.item.patientByAge.adult.patient}}
                      </template>
                    </div>
                    <div class="col-sm-6">
                      <template v-if="data.item.patientByAge.child.service + data.item.patientByAge.adult.service === 0"></template>
                      <template v-else>{{ Math.round( parseFloat(data.item.patientByAge.child.service + data.item.patientByAge.adult.service)) }}</template>
                    </div>
                  </div>
                </template>
            </b-table>
          </div>
        </div>
      </div>
      <vue-snotify></vue-snotify>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
  
  export default {
    name: "AgeWiseService",
    data() {

      return {
        filter: {
            type: 'center',
            period: 'this_month'
        },
        periodConverter: {
          this_month: 'This Month',
          this_week: 'This Week',
          last_month: 'Last Month',
          last_week: 'Last Week'
        },
        fields: [
        {
            key: 'serviceName',
            sortable: false,
            label: 'Service Type',
          },
          {
            key: 'childPatientByAge',
            label: 'Child',
            sortable: false,
          },
          {
            key: 'adultPatientByAge',
            label: 'Adult',
            sortable: false
          },
          {
            key: 'total',
            label: 'Total',
            sortable: false
          },
        ],
        items: [],
        serviceConverter:{
          zcf: 'ZCF',
          pht: 'PHT',
          cp: 'CP',
          autism: 'Autism',
          bow_leg: 'Rickets/Bow Leg',
          zcf_pht: 'ZCF to PHT',
          zcf_cp: 'ZCF to CP',
          zcf_autism: 'ZCF to Autism',
          zcf_bow_leg: 'ZCF to Rickets/Bow Leg',
          pht_zcf: 'PHT to ZCF',
          pht_cp: 'PHT to CP',
          pht_autism: 'PHT to Autism',
          pht_bow_leg: 'PHT to Rickets/Bow Leg',
          cp_zcf: "CP to ZCF",
          cp_pht: "CP to PHT",
          cp_autism: "CP to Autism",
          cp_bow_leg: 'CP to Rickets/Bow Leg',
          autism_zcf: "Autism to ZCF",
          autism_pht: "Autism to PHT",
          autism_cp: "Autism to CP",
          autism_bow_leg: "Autism to Rickets/Bow Leg",
          bow_leg_zcf: "Rickets/Bow Leg to ZCF",
          bow_leg_pht: "Rickets/Bow Leg to PHT",
          bow_leg_cp: "Rickets/Bow Leg to CP",
          bow_leg_autism: "Rickets/Bow Leg to Autism"
        }
      }
    },
    mounted() {
      if(this.$route.query.period){
        this.filter.period = this.$route.query.period
      }
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Age Wise Service", route: "/service/age-wise-service"},
      ]);
      this.getAgeWiseData();
      
    },
    methods: {
        getAgeWiseData() {
            this.loading = true;
            this.ageWiseData = {}
            axios.get(`${process.env.VUE_APP_API_URL}/api/v2/orko-dashboard/age-wise-services/details?api_token=${localStorage.getItem('api_token')}${this.filter.type ? '&type=' + this.filter.type : ''}${this.filter.period ? '&period=' + this.filter.period : ''}`)
            .then(res => {
                this.ageWiseData = res.data.data;
                let newArr = [];
                Object.entries(this.serviceConverter).forEach((arr) => {
                    newArr.push({serviceName: arr[1],patientByAge: this.ageWiseData[arr[0]]})
                });
                // this.items = Object.entries(this.ageWiseData).map((arr) => ({
                //     serviceName: arr[0],
                //     patientByAge: arr[1],
                // }));
                this.items = newArr;
                this.loading = false;
            })
        },

        setPeriod(period) {
          this.filter.period = period;
          this.$router.push({ path: "/service/age-wise-service", query: { period: period } });
          this.getAgeWiseData();
        },

    },
    watch: {
        filter: {
            handler() {
              this.getAgeWiseData();
            },
            deep: true,
            immediate: true,
        },
    },
  
  }
  </script>
  
  <style lang="scss">

  table{
    &.c_tbl{
      thead{
        border-radius: 10px;
          overflow: hidden;
        tr{
          border-radius: 10px;
          overflow: hidden;
          th{
            padding: 15px;
            font-size: 18px;
            background-color: #002147 !important;
            color: #f26f25 !important;
            font-weight: 600;
            &:first-child{
              text-align: left;
            }
          }
        }
      }
      tbody{
        tr{
          td{
            padding: 16px;
            font-size: 14px;
            font-weight: 600;
            &:first-child{
              text-align: left;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .custom-height {
    height: 30px !important;
  }
  </style>
  