<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row mb-5 align-items-center">
          <div class="col-md-2">
            <label for="start-datepicker">Start date</label>
            <b-form-datepicker id="start-datepicker" v-model="start_date" class="mb-2"></b-form-datepicker>
          </div>
          <div class="col-md-2">
            <label for="end-datepicker">End date</label>
            <b-form-datepicker id="end-datepicker" v-model="end_date" class="mb-2"></b-form-datepicker>
          </div>

          <div class="col-md-2">
            <label for="">Select center</label>
            <v-select
                :options="centers"
                :reduce="centers => centers.id"
                v-model="institute_id"
                label="name"
                class="h-100"
                name="id"
                placeholder="Select center"
                required>
            </v-select>
          </div>

          <div class="col-md-2 mt-8">
            <b-form-select class="filter-font" :class="select.status ? 'bg-active' : ''" v-model="select.status"
                           :options="statuses"></b-form-select>
          </div>
          <div class="col-md-2 mt-8">
            <b-button @click="getAppointmentList" variant="primary">Filter</b-button>
          </div>
          <div class="col-md-1 mt-8">
            <b-button @click="getAppointmentList" variant="info"><i class="fas fa-redo-alt"></i></b-button>
          </div>
          <div class="col-md-1 mt-8">
            <b-button @click="clearFilter" variant="danger"><i class="fas fa-eraser"></i></b-button>
          </div>

        </div>


        <div class="row">
          <div class="col-md-12">
            <b-table
                responsive
                striped hover
                :items="items"
                :fields="fields"
                thead-class="bg-primary text-white"
                :current-page="currentPage"
                :per-page="0"
                class="mt-5"
                show-empty>

              <template #cell(doctor_id)="row">
                <div>
                  <b-media tag="li" class="">
                    <template #aside>
                      <div class="text-center">
                        <b-img :src="userPhoto(row.item.doctor_image)" width="85" class="thumbnail" thumbnail></b-img>
                        <!--                        <star-rating :value="`${row.item.doctor_review}`" :disabled="true"></star-rating>-->
                        <!--                        <p class="mb-0">Exp: <b>{{ row.item.doctor_exp }} Year</b></p>-->
                      </div>
                    </template>
                    <h5 class="mt-0 mb-1">
                      <router-link :to="`user/details/${row.item.doctor_id}`">{{ row.item.doctor_prefix }}
                        {{ row.item.doctor_name }}
                      </router-link>
                    </h5>
                    <p class="mb-0 font-weight-bold">{{ row.item.doctor_phone_number }}</p>
                    <!--                    <p class="mb-0">{{ row.item.doctor_degree }}</p>-->
                    <!--                    <p class="mb-0">{{ row.item.institute_name }}</p>-->
                  </b-media>
                </div>
              </template>

              <template #cell(patient_id)="row">
                <div>
                  <b-media tag="li">
                    <template #aside>
                      <b-img v-if="row.item.patient_image != '/storage/'" blank-color="#ccc"
                             :src="userPhoto(row.item.patient_image)" width="80" thumbnail></b-img>
                      <b-img v-else blank blank-color="#ccc" width="80"></b-img>
                    </template>
                    <h5 class="mt-0 mb-1">
                      <router-link :to="`user/details/${row.item.patient_id}`">{{ row.item.patient_name }}</router-link>
                    </h5>
                    <p class="mb-0">{{ row.item.patient_age }}</p>
                    <p class="mb-0">{{ row.item.patient_phone }}</p>
                  </b-media>
                </div>
              </template>

              <template #cell(appointment_date)="row">
                <div>
                  <p>{{ row.item.appointment_date }}</p>
                </div>
              </template>


              <template #cell(schedule_date)="row">
                <div>
                  <h4>{{ row.item.schedule_date }}</h4>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <b-badge variant="success">start: {{ row.item.schedule_started_at }}</b-badge>
                </div>
              </template>

              <template #cell(is_paid)="row">
                <div>
                  <b-badge variant="success" v-if="row.item.is_paid == 1">Paid</b-badge>
                  <b-badge variant="warning" v-else-if="row.item.is_paid == 2">Partial paid (AP)</b-badge>
                  <b-badge variant="danger" class="mt-3 cursor-pointer" v-else>
                    Unpaid
                  </b-badge>
                </div>
              </template>

              <template #cell(appointment_status)="row">
                <div>
                  <b-badge variant="warning" v-if="row.item.appointment_status == 'expired'">
                    {{ row.item.appointment_status }}
                  </b-badge>
                  <b-badge variant="success" v-else-if="row.item.appointment_status == 'booked'">
                    {{ row.item.appointment_status }}
                  </b-badge>

                  <b-badge variant="danger" v-else-if="row.item.appointment_status == 'canceled'">
                    {{ row.item.appointment_status }}
                  </b-badge>

                  <b-badge variant="info" v-else-if="row.item.appointment_status == 'rescheduled'">
                    {{ row.item.appointment_status }}
                  </b-badge>

                  <b-badge variant="danger" v-else-if="row.item.appointment_status == 'patient-absent'">
                    {{ row.item.appointment_status }}
                  </b-badge>

                  <b-badge variant="primary" v-else-if="row.item.appointment_status == 'served'">
                    {{ row.item.appointment_status }}
                  </b-badge>
                  <b-badge variant="danger" class="mt-3" v-else>{{ row.item.appointment_status }}</b-badge>
                  <br>
                  <b-badge variant="success" class="mt-3" v-if="row.item.is_followup==1">Followup</b-badge>
                </div>
              </template>


              <template #cell(payable_amount)="row">
                <div>
                  <h4>
                    {{ row.item.payable_amount }}
                  </h4>
                </div>
              </template>


              <template #cell(appointment_type)="row">
                <div>
                  <p variant="success">
                    {{ underScoreToSpace(row.item.appointment_type) }}
                  </p>

                </div>
              </template>


            </b-table>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                size="lg"
                class="my-0"
                @change="getAppointmentList"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>

    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {underScoreToSpace} from "../../healper/nameFormating";

export default {
  name: "AppointmentWiseReport",
  data() {
    return {
      loading: true,
      items: [],

      fields: [
        {
          key: 'doctor_id',
          label: 'Doctor/Physio',
          tdClass: 'test',
          'class': 'my-class',
          'no': {thStyle: {width: '400px !important'}},
          sortable: true,
        },
        {
          key: 'patient_id',
          label: 'Patient',
          sortable: true,
        },
        {
          key: 'institute_name',
          label: 'Institute',
          sortable: true,
        },
        {
          key: 'schedule_date',
          label: 'Schedule date',
          sortable: true,
        },

        {
          key: 'appointment_status',
          label: 'Appointment status',
          sortable: true,
        },
        {
          key: 'appointment_type',
          label: 'Appointment type',
          sortable: true,
        },
        {
          key: 'payable_amount',
          label: 'Payable amount',
          sortable: true,
        },
        {
          key: 'is_paid',
          label: 'Payment Status',
          sortable: true,
        },

      ],
      currentPage: 1,
      perPage: 20,
      totalItems: 0,
      select: {
        status: null,
        appointment_type: null,
        service_type: null,
      },
      statuses: [
        {value: null, text: 'Appointment status'},
        {value: 'rescheduled', text: 'Rescheduled'},
        {value: 'canceled', text: 'Canceled'},
        {value: 'patient-absent', text: 'Patient absent'},
        {value: 'expired', text: 'Expired'},
        {value: 'booked', text: 'Booked'},
        {value: 'served', text: 'Served'},
        {value: 'pending', text: 'Pending'},
      ],

      start_date: '',
      end_date: '',
      centers: [],
      institute_id: '',
      center_ids: '',
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Center wise appointment", route: "/center-wise-appointment"},
    ]);
  },
  created() {
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
    // this.getAppointmentList();
    this.centerList();
  },
  methods: {

    underScoreToSpace,

    getAppointmentList(value) {
      if (Number(value)) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      this.loading = true;
      const url = `${process.env.VUE_APP_API_URL}/api/v2/appointment/list/new?api_token=${localStorage.getItem('api_token')}&limit=20&offset=${correctValue ? correctValue : 0}${this.institute_id ? '&workplace_id=' + this.institute_id : ''}${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}&center_ids=${this.center_ids}`
      axios.get(url)
          .then(response => {

            this.totalItems = response.data.total_count;
            this.items = response.data.data;
            this.loading = false;
          })
    },
    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.loading = false
              this.centers = response.data.data
             let center_ids = response.data.data.map(item => {
                return item.id
              });
              this.center_ids = center_ids.join(',')
              this.getAppointmentList();
            }
          })
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return "../../assets/img/blank.png";
      }
    },
    clearFilter() {
      this.select = {
        status: null,
        appointment_type: null,
        service_type: null,
      };
      this.start_date = null;
      this.end_date = null;
      this.institute_id = '';
    },
  },

}
</script>

<style scoped>

</style>
